import React from 'react'
import withStyles from '@material-ui/core/styles/withStyles'
import Footer from 'components/Footer/Footer.jsx'

import { graphql, useStaticQuery } from 'gatsby'

import Header from '../../components/Header/Header'
import PageHeader from '../../components/PageHeader/PageHeader'
import Section from '../../components/Section/Section'
import BlogSection from '../../elements/RecentBlogPosts/RecentBlogPosts'
import InstagramSection from '../../components/InstagramSection/InstagramSection'
import SpainHolidaysCta from '../../elements/SpainholidaysCta/SpainHolidaysCta'
import ueberwinternStyle from './ueberwinternStyle'

import Content from '../../elements/Content/Content'
import ThreeArgumentsSection from '../../elements/threeArgumentsSection/threeArgumentsSection'


const Ueberwintern = props => {
  const data = useStaticQuery(graphql`
    query {
      datoCmsUeberwintern{
        slug
        titel
        untertitel
        hintergrundbild{
          alt
          fluid(maxWidth:1800){
            src
            ...GatsbyDatoCmsFluid_noBase64
          }}
        argumentsTitle
        arguments{
          title
          subtitle
          image{
            alt
            fluid(maxWidth:500){
              src
              ...GatsbyDatoCmsFluid_noBase64
            }
          }
        }
        content{
          __typename
          ...on DatoCmsH2{
            heading
          }
          ...on DatoCmsH3{
            heading
          }
          ...on DatoCmsH4{
            heading
          }
          ...on DatoCmsH5{
            heading
          }
          ...on DatoCmsH6{
            heading
          }
          ...on DatoCmsText{
            text
          }
          ...on DatoCmsImage{
            image{
              alt
              fluid(maxWidth: 705) {
                src
                ...GatsbyDatoCmsFluid_noBase64
              }}
          }

        }
      }
      datoCmsHome{
        spainholidayBanner{
          title
          subtitle
          buttonLink
          buttonTitle
        }
      }
    }
  `)

  return (
    <div>
      <Header
        SEOtitle={data.datoCmsUeberwintern.titel}
        SEOdescription={data.datoCmsUeberwintern.untertitel}
        SEOimage={data.datoCmsUeberwintern.hintergrundbild.fluid.src}
        SEOpathname={data.datoCmsUeberwintern.slug}
      />
      <PageHeader
        image={data.datoCmsUeberwintern.hintergrundbild.fluid}
        alt={data.datoCmsUeberwintern.hintergrundbild.alt}
        title={data.datoCmsUeberwintern.titel}
        subtitle={data.datoCmsUeberwintern.untertitel}

      />

      <ThreeArgumentsSection
        title={data.datoCmsUeberwintern.argumentsTitle}
        threeArguments={data.datoCmsUeberwintern.arguments}
      />
      <Section innerStyles={{ maxWidth: 705 }} paddingTop={80} paddingBottom={80}>
        <Content
          content={data.datoCmsUeberwintern.content}
        />
      </Section>
      <BlogSection/>
      <InstagramSection/>
      <SpainHolidaysCta
        title={data.datoCmsHome.spainholidayBanner[0].title}
        subtitle={data.datoCmsHome.spainholidayBanner[0].subtitle}
        buttonTitle={data.datoCmsHome.spainholidayBanner[0].buttonTitle}
        buttonLink={data.datoCmsHome.spainholidayBanner[0].buttonLink}
      />


      <Footer/>
    </div>
  )
}

export default withStyles(ueberwinternStyle)(Ueberwintern)
